import React from 'react';
import { Row, Col } from 'antd';
import Text from 'antd/lib/typography/Text';
import Currency from '../currency/currency';
import { useDispatch } from 'react-redux';
import { actionCreators as BookingActions } from '../../store/Booking';

export interface ISummarySubtotalItemProps {
    long?: boolean;
    label: string;
    value?: number;
    currency: string;
    locale: string;
    translationKey?: string | undefined;
    className?: string;
    showCode?: boolean;
    removeCode?: string;
}

const SummarySubtotalItem: React.FunctionComponent<ISummarySubtotalItemProps> = (props) => {
    const dispatch = useDispatch();

    const onRemoveCodeClicked = (value?: string) => {
        if(value) {
            dispatch(BookingActions.removeCode(value));
        }
    }

    if (props.value === undefined) return <React.Fragment />;
    if (props.currency === undefined || props.currency === '') return <React.Fragment />;

    if (props.long)
        return (
            <Row
                align="middle"
                justify="end"
                className={`subtotal-item ${props.className !== undefined ? `${props.className}` : ''}`}
            >
                <Col span={24}>
                    <Currency
                        translationKey={props.label}
                        value={props.value}
                        locale={props.locale}
                        currency={props.currency}
                    />
                </Col>
            </Row>
        );

    return (
        <Row
            align="middle"
            justify="end"
            className={`subtotal-item  ${props.className !== undefined ? `${props.className}` : ''}`}
        >
            <Col span={14}>
                <Text strong>{props.label} {props.removeCode && (<a onClick={() => onRemoveCodeClicked(props.removeCode)}>[X]</a>)}</Text>
            </Col>
            <Col span={10} className="currency">
                <Currency
                    value={props.value}
                    locale={props.locale}
                    currency={props.currency}
                    showCode={props.showCode}
                />
            </Col>
        </Row>
    );
};

export default SummarySubtotalItem;
