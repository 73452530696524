import { useEffect } from 'react';

const useExitDetection = (callback: any) => {
    useEffect(() => {

        // Popstate Event
        const handlePopState = () => {
            callback('popState');
        };

        // Visibility change Event
        const handleVisibilityChange = () => {
            if (document.hidden) {
                callback('visibilityChange');
            }
        };
        
        // Mouse Out Event (Desktop)
        const handleMouseOut = (event: any) => {
            if (!event.relatedTarget) {
                callback('mouseOut');
            }
        };

        // Check if the mouse is moving upwards and near the top of the viewport
        const handleMouseMove = (event: any) => {
            if (event.clientY <= 10 && event.movementY < 0) {
                callback('mouseMoveToTop');
            }
        };

        const handlePageHide = () => {
            callback('pageHide');
        };

        // Change Apps (Mobile)
        const handleWindowBlur = () => {
            callback('windowBlur');
        };

        // Touch Events (Mobile)
        // Scrolling up more then 300px at a time
        let touchStartY: any = null;
        const handleTouchStart = (event: any) => {
            touchStartY = event.touches[0].clientY;
        };
        const handleTouchEnd = (event: any) => {
            const touchEndY = event.changedTouches[0].clientY;
            if (touchEndY - touchStartY > 300) {
                callback('touchSwipeUp');
            }
        };

        window.addEventListener('popstate', handlePopState);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseout', handleMouseOut);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);
        window.addEventListener('blur', handleWindowBlur);
        window.addEventListener('pagehide', handlePageHide);


        return () => {
            window.removeEventListener('popstate', handlePopState);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseout', handleMouseOut);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
            window.removeEventListener('blur', handleWindowBlur);
            window.removeEventListener('pagehide', handlePageHide);

        };
    }, [callback]);
};

export default useExitDetection;