import { Action, Reducer } from 'redux';

import i18n from '../i18n';
import { languageList } from '../helpers/lang';
import { setLanguage } from '../helpers/i18n';
import { IAppThunkAction } from './';

export interface IInternationalisationState {
    currentLocale: string;
    fallbackLocale: string;
    rtl: boolean;
    momentLocale: string;
}

export const CHANGE_LOCALE: 'CHANGE_LOCALE' = 'CHANGE_LOCALE';

export interface IChangeLocaleAction {
    type: typeof CHANGE_LOCALE;
    locale: string;
    rtl: boolean;
    momentLocale: string;
}

const undefinedState: IInternationalisationState = {
    currentLocale: 'en-US',
    fallbackLocale: 'en-US',
    rtl: false,
    momentLocale: 'en-au',
};

export type KnownInternationalisationActions = IChangeLocaleAction;

export const actionCreators = {
    changeLocale: (locale: string): IAppThunkAction<KnownInternationalisationActions> => (dispatch) => {
        const lang = languageList.find((lng) => lng.code === locale);

        if (lang === undefined || i18n.language === lang.code) return null;

        setLanguage(lang);

        dispatch({
            type: CHANGE_LOCALE,
            locale: lang?.code ?? undefinedState.currentLocale,
            rtl: lang?.rtl === true,
            momentLocale: lang?.momentLocale ?? undefinedState.momentLocale,
        });
    },
    setLocale: (locale: string): IAppThunkAction<KnownInternationalisationActions> => (dispatch) => {
        const lang = languageList.find((lng) => lng.code === locale);

        if (lang === undefined || i18n.language === lang.code) return null;

        setLanguage(lang);
    }
};

export const reducer: Reducer<IInternationalisationState> = (
    state: IInternationalisationState | undefined,
    incomingAction: Action
): IInternationalisationState => {
    const action: KnownInternationalisationActions = incomingAction as KnownInternationalisationActions;
    switch (action.type) {
        case CHANGE_LOCALE: {
            return {
                currentLocale: action.locale,
                fallbackLocale:
                    state?.fallbackLocale === undefined || state?.fallbackLocale === '' ? action.locale : '',
                rtl: action.rtl,
                momentLocale: action.momentLocale,
            };
        }
        default:
            if (state === undefined) return undefinedState;
            return state;
    }
};
