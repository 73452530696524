import React, { useRef, useState, useEffect } from 'react';
import BrazeEvents from '../../helpers/braze';
import useExitDetection from "./exit-detection"

const ExitIntentComponent = () => {

    const lastExitIntentTimeRef = useRef(0);
    const timeoutDuration = 5000;

    const handleExitIntent = (eventType: any) => {

        const now = Date.now();

        if (now - lastExitIntentTimeRef.current >= timeoutDuration) {
            //console.log(`Exit intent detected from ${eventType}`);
            BrazeEvents.LogExitIntent();

            lastExitIntentTimeRef.current = now;
        }};

    useExitDetection(handleExitIntent);

    return <></>;
}

export default ExitIntentComponent;
